@import "../base/_base.scss";

#submissions {
  .submission {
    border-top: 1px solid $gray-lighter;
    border-bottom: 1px solid $gray-lighter;
    border-left: 1px solid $gray-lighter;
    border-right: 1px solid $gray-lighter;
    border-radius: 10px;
    padding-left: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px;

    .heading {
      // margin-bottom: 1em;

      h3 {
        margin-bottom: 0px;
      }

      .delete-link {
        margin-left: 1em;
      }
    }
  }
}

.number-arrow  {
  display: block;
  min-height: 4em;
  }
.progress-indicator {
  .step {
    @extend .clearfix !optional;
    position: relative;

    .number {
      border: 1px solid $gray-med-dark;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background: #fefefe;
      color: $gray-med-dark;
      font-size: 16px;
      text-align: center;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      float: left;
      clear: both;
      margin: 0em .25em 1.25em 0em;    //0px 10px 35px 0px;
    }

    .information {
      display: block;
      width: 100%;

      .description {
       @media screen and (min-width: 768px) {
          position: relative;
          top: 3em;
          left: 3em;
       }
        @extend .text-muted;
        margin: 0px;
        padding-top: 0px;
        padding-bottom: 5px;
        font-size: 16px;
        color: $gray-med-dark;
        .small {
          top: -1px;
          position: relative;
        }
      }



      .status {
        font-size: 100%;
        display: block;
        color: $gray-light;
        vertical-align: top;
        line-height: 20px;

        .fa-check-circle-o {
          vertical-align: bottom;
          font-size: 130%;
          color: $brand-success;
        }

        .fa-warning {
          position: relative;
          left: 1px;
          top: 1px;
          margin-right: 3px;
          font-size: 130%;
          color: $brand-warning;
        }

        .fa-exclamation-circle {
          position: relative;
          margin-right: 2px;
          font-size: 150%;
          color: $brand-danger;

        }
        a {
          vertical-align: text-bottom;
        }
      }
    }


    .final {
      font-size: 130%;
      margin-left: 0.5em;
    }

    .direction {
        position: relative;
        top: 3em;
        left: -2em;
        color: $gray-med-dark;
    }

    &.complete {
      .information {
      }

      .number {
        border-color: $green-dark;
        border-width: 2px;
        background-color: white;
        color: $green-dark;
      }

      .description {
        color: inherit;
        padding-top: 0px;
      }

      .direction {
        color: $gray-med-dark;
      }
    }

    &.current {
      .information {
    }

        .number {
          border: 1px solid $gray-med-dark;
          background: #fefefe;
          border-color: $brand-primary;
          border-width: 2px;
          color: $brand-primary;
      }

      .description {
        padding-top: 0;
        width: 100%;
        color: inherit;
      }

      &.step-4, &.step-6 {
        .description {
          color: inherit;
        }
      }
    }
  }

  &.preview, &.in-process{

      .header {
        margin-top: 30px;
        color: $gray-med-dark;
        font-size: 20px;
        font-weight: bold;
      }

      .number {
        border: 1px solid $gray-med-dark;
      }

      .information {
        display: inline-block;
        width: 100%;

        .description {
          display: inline-block;
          margin-top: 0px;
          margin: 0px;
          font-size: 16px;
        }
      }
  }
}
.step.complete.final {
  padding-bottom: 1.5em;
}
.new-button {
  margin: 1em .5em;
}

.h2-small {
  font-size: 120%
}

.contact-info dt {
  float:left;
  width:40%; /* adjust the width; make sure the total of both is 100% */
}
.contact-info dd {
  float:left;
  width:60%; /* adjust the width; make sure the total of both is 100% */
}

a.text-danger.can-toggle-clickability.remove_fields.dynamic {
  padding:0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.help-block {
  color: #A9445a;
}

.published-submissions {
  font-size: medium;
  margin-top: 1em;

  .title {
    font-size: large;
  }
}

.pull-up {
  margin-top: 5px;
}
