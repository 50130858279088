/* Optional scoped theme for tag-it which mimics the zendesk widget. */

ul.tagit {
  border-style: solid;
  border-width: 1px;
  border-color: #C6C6C6;
  background: inherit;
  li.tagit-choice {
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    border: 1px solid #CAD8F3;
    background: none;
    background-color: #DEE7F8;
    font-weight: normal;
    .tagit-label:not(a) {
      color: #555;
    }
    a.tagit-close {
      text-decoration: none;
    }
    .tagit-close {
      right: .4em;
    }
    .ui-icon {
      display: none;
    }
    .tagit-close .text-icon {
      display: inline;
      font-family: arial, sans-serif;
      font-size: 16px;
      line-height: 16px;
      color: #777;
    }
    &:hover, &.remove {
      background-color: #bbcef1;
      border-color: #6d95e0;
    }
    a {&.tagLabel:hover, &.tagit-close .text-icon:hover {
      color: #222;
    }
    }
  }
  input[type="text"] {
    color: #333333;
    background: none;
  }
}

.ui-widget {
  font-size: 1.1em;
}

/* Forked from a jQuery UI theme, so that we don't require the jQuery UI CSS as a dependency. */

.tagit-autocomplete.ui-autocomplete {
  position: absolute;
  cursor: default;
}

* html .tagit-autocomplete.ui-autocomplete {
  width: 1px;
}

/* without this, the menu expands to 100% in IE6 */

.tagit-autocomplete {
  &.ui-menu {
    list-style: none;
    padding: 2px;
    margin: 0;
    display: block;
    float: left;
    .ui-menu {
      margin-top: -3px;
    }
    .ui-menu-item {
      margin: 0;
      padding: 0;
      zoom: 1;
      float: left;
      clear: left;
      width: 100%;
      a {
        text-decoration: none;
        display: block;
        padding: .2em .4em;
        line-height: 1.5;
        zoom: 1;
      }
    }
  }
  .ui-menu .ui-menu-item a {
    &.ui-state-hover, &.ui-state-active {
      font-weight: normal;
      margin: -1px;
    }
  }
  &.ui-widget-content {
    border: 1px solid #aaaaaa;
    background: #ffffff 50% 50% repeat-x;
    color: #222222;
  }
  &.ui-corner-all, .ui-corner-all {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    border-radius: 4px;
  }
  .ui-state-hover, .ui-state-focus {
    border: 1px solid #999999;
    background: #dadada;
    font-weight: normal;
    color: #212121;
  }
  .ui-state-active, .ui-widget-content {
    border: 1px solid #aaaaaa;
  }
}

.tagit .ui-helper-hidden-accessible {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

